import axios from "axios";
import { MessageBox, Message } from "element-ui";
import router from "../router";

let onlyShowOnceDialog = true;
const request = axios.create();
request.defaults.baseURL = process.env.VUE_APP_BASEURL;

const token = localStorage.getItem("token") ?? sessionStorage.getItem("token");
if (token) request.defaults.headers.token = token;

request.interceptors.request.use(
    function (config) {
        const token = localStorage.getItem("token") ?? sessionStorage.getItem("token");
        if (token) config.headers.token = token;
        return config;
    },
    function (error) {
        Message({
            message: error,
            showClose: true,
            type: "warning",
        });
        return false;
    }
);

request.interceptors.response.use(
    function (response) {
        if (response.data.code === 200) {
            return response.data.data;
        } else if (response.data.code === 403) {
            localStorage.removeItem("token");
            sessionStorage.removeItem("token");
            if (onlyShowOnceDialog) {
                onlyShowOnceDialog = false;
                MessageBox({
                    title: "提示",
                    message: response.data.message,
                    type: "warning",
                    showClose: false,
                    closeOnClickModal: false,
                }).then(() => {
                    onlyShowOnceDialog = true;
                    router.replace({ path: "/login" });
                });
            }
            return false;
        } else {
            Message({
                message: response.data.message,
                showClose: true,
                type: "warning",
            });
            return false;
        }
    },
    function (error) {
        Message({
            message: error,
            showClose: true,
            type: "warning",
        });
        console.error(error);
        return false;
    }
);

export default request;
