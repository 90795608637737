// 表格头部样式
export function tableHeaderColor() {
  return 'background-color:#408eff;height:55px;color:#e1edff;font-size:15px'
}

// 表格row的样式
export function rowClass() {
  return 'height:61px;font-weight: 500;color: #333333;'
}

// 带小手的样式
export function cursorClass() {
  return 'height:61px;font-weight: 500;color: #333333;cursor:pointer;'
}