<script>    
    export default{
        rid:'',
        rname:'',
        type:'',
        setRid(rid,rname,type){
            this.rid=rid;
            this.rname=rname;
            this.type=type
        },
        getRid(){
            return this.rid;
        }
        
    }
</script>