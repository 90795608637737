<template>
  <div class="tableBox">
    <el-table :data="tableData" style="width: 100%" stripe :header-cell-style="tableHeaderColor" :cell-style="rowClass"
      :cell-class-name="addClass" @cell-click="cellclick" @cell-mouse-enter="cellmouse">
      <template v-for="(item, index) in tableList">
        <el-table-column v-if="item.prop != 'op' && !item.multistage" show-overflow-tooltip :prop="item.prop"
          :label="item.label" :width="item.width" align="center" :key="index">
          <template slot-scope="scope">
            <span :style="{ color: item.color ? item.color(scope.row[item.prop]) : '', }">
              {{ item.formatter ? item.formatter(scope.row) : scope.row[item.prop] }}

            </span>
            <span v-if="item.prop == 'index'" :style="{ color: item.color ? item.color(scope.row[item.prop]) : '', }">{{
                scope.$index + 1
            }}</span>

          </template>
        </el-table-column>
        <el-table-column v-if="item.multistage" show-overflow-tooltip :label="item.label" :width="item.width"
          align="center" :key="index">
          <el-table-column v-for="(multistage, index) in item.options" show-overflow-tooltip :prop="multistage.prop"
            :label="multistage.label" :width="multistage.width" align="center" :key="index">
            <template slot-scope="scope">
              <span :style="{ color: multistage.color ? multistage.color(scope.row[multistage.prop]) : '', }">

                {{ multistage.formatter ? multistage.formatter(scope.row) : scope.row[multistage.prop] }}

              </span>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column v-if="item.prop == 'op'" :label="item.label" :width="item.width" align="center" :key="index">
          <template slot-scope="scope">
            <template v-if="item.btn.length != 0">
              <template v-for="(btn, i) in item.btn">
                <el-button :disabled='operShow' v-if="btn.isHide ? !btn.isHide(tableData[scope.$index]) : true" size="small" :key="i" plain
                  :type="btn.type" @click="onHandler(btn.prop, tableData[scope.$index])"
                  :class="'table-btn-' + btn.type">{{ btn.name }}</el-button>
              </template>
            </template>
          </template>
        </el-table-column>
      </template>
    </el-table>
    <div class="pagination">
      <el-pagination ref="Pagination" background layout="total, prev, pager, next, jumper"
        :current-page.sync="currentPage" @current-change="handleCurrentChange" :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { tableHeaderColor, rowClass, cursorClass } from "@/utils/table";
export default {
  name: "Table",
  data() {
    return {
      currentPage: 1,
    };
  },
  props: {
    tableList: {
      type: Array,
      default() {
        return [];
      },
    },
    tableData: {
      type: Array,
      default() {
        return [];
      },
    },
    total: {
      type: Number,
      default() {
        return 0;
      },
    },
    operShow: {
      type: Boolean,
      default() {
        return false
      }
    },
    navtor: Boolean,
  },
  created() {
    console.log(this.tableData);
  },
  methods: {
    tableHeaderColor({ row, rowIndex }) {
      return tableHeaderColor();
    },
    rowClass({ row, column, rowIndex, columnIndex }) {
      if (this.navtor) {
        if (columnIndex === 1) {
          return cursorClass();
        }
      } else {
        return rowClass();
      }
    },
    addClass({ row, column, rowIndex, columnIndex }) {
      // console.log(columnIndex)
      if (columnIndex === 2) {
        return "resAndSug";
      }
    },
    onHandler(val, data) {
      this.$emit("operation", { opVal: val, data });
    },
    handleCurrentChange(val) {
      this.$emit("currentNum", val);
    },
    cellclick(row, column, event, cell) {
      this.$emit("cellclick", { row, column, event, cell });
      // console.log(row)
      // console.log(column)
      // console.log(event)
      // console.log(cell)
    },
    cellmouse(row, column, event, cell) {
      this.$emit("cellmouse", { row, column, event, cell });
    },

    toPublic(row, event, column) {
      console.log(row, event, column);
    },
    lastEmittedPage() {
      this.currentPage = 1;
    },
  },
};
</script>

<style>
.el-table--striped .el-table__body tr.el-table__row--striped td {
  background: #e8ecf7 !important;
}

.el-tooltip__popper {
  max-width: 800px;
}
</style>
<style lang="scss" scoped>
.tableBox {
  .pagination {
    text-align: center;
    margin: 50px 0px;

  }
}

.resAndSug {
  color: aqua;
  cursor: pointer;
}

@media screen and (max-height: 1080px) {
  .tableBox {
    .pagination {
      text-align: center;
      margin: 5% 0px;
      background-color: #FFFFFF;
    }
  }
}

@media screen and (max-height: 970px) {
  .tableBox {
    .pagination {
      text-align: center;
      margin: 3% 0px;

    }
  }
}
</style>
